.pricing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    width: 100%;

    .pricing-heading {
        font-size: 35px;
        font-weight: 500;
        color: rgb(0, 0, 0);
        padding-bottom: 10px;

        @media (max-width: 768px) {
            font-size: 20px;
        }
        @media (max-width: 1439px) {
            font-size: 24px;
        }
    }

    .pricing-cards-container {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        padding-bottom: 10px
    }

    .custom-subs-btn {
        background-color: rgba(0, 41, 255, 1) !important;
        color: white !important;
    }

    .period-toggler-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }

    .billing-period-text {
        font-weight: 500;
        color: rgba(102, 112, 133, 1);

        @media (min-width: 768px) {
            font-size: 16px;
        }

        @media (max-width: 768px) {
            font-size: 15px;
        }

        &.active {
            color: rgba(0, 41, 255, 1);
        }
    }

    .faq-container {
        max-width: 840px;
        display: flex;
        margin-bottom: 40px;
    }
}